<template>
  <div class="main">
    <el-row :gutter="20">
      <el-col :span="4">
        <div class="side">
          <el-menu
              :default-active="$route.path"
              class="el-menu-vertical-demo overflow"
              @open="handleOpen"
              @close="handleClose"
              :router="true"
              :unique-opened="true"
              text-color="#333"
              v-if="userType == 4"
          >
            <el-menu-item index="/center/indexs">
              <img
                  src="~/static/center/per_black.png"
                  alt
                  class="block_inline"
              />
              <span slot="title">个人中心</span>
            </el-menu-item>
            <el-menu-item index="/center/annual/">
              <img src="~/static/center/qiuzhi.png" alt aria-hidden="true" class="block_inline" />
              <span slot="title">年审员培训</span>
            </el-menu-item>
            <el-menu-item index="/center/myask/" v-if="location == ''">
              <img src="~/static/center/Q.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的问答</span>
            </el-menu-item>
            <el-menu-item index="/center/column/" v-if="location == ''">
              <img src="~/static/center/column.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的专栏</span>
            </el-menu-item>
            <el-menu-item index="/center/collect/">
              <img src="~/static/center/collect.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的收藏</span>
            </el-menu-item>

<!--            <el-submenu index="/settingUser/">-->
<!--              <template slot="title">-->
<!--                <img-->
<!--                    src="~/static/enterprise/setting.png"-->
<!--                    alt-->
<!--                    class="block_inline"-->
<!--                />-->
<!--                <span slot="title">功能设置</span>-->
<!--              </template>-->
<!--              <el-menu-item-group>-->
<!--                <el-menu-item index="/settingUser/">-->
<!--                  <span slot="title" class="fonts"></span>-->
<!--                </el-menu-item>-->
<!--              </el-menu-item-group>-->
<!--            </el-submenu>-->
            <el-menu-item index="/settingUser/">
              <img src="~/static/enterprise/setting.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">社交账号绑定</span>
            </el-menu-item>
            <el-menu-item @click="logout">
              <img src="~/static/center/out.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">退出登录</span>
            </el-menu-item>
          </el-menu>
          <el-menu
              :default-active="$route.path"
              class="el-menu-vertical-demo overflow"
              @open="handleOpen"
              @close="handleClose"
              :router="true"
              :unique-opened="true"
              text-color="#333"
              v-else
          >
            <el-menu-item index="/center/index">
              <img
                  src="~/static/center/per_black.png"
                  alt aria-hidden="true"
                  class="block_inline"
              />
              <span slot="title">个人中心</span>
            </el-menu-item>
            <el-menu-item index="/center/annual/" v-if="location != '陕西省' && location != '甘肃省'">
              <img src="~/static/center/qiuzhi.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">年审员培训</span>
            </el-menu-item>
            <el-menu-item
                :route="{
                path: '/center/resume',
                query: { id: memberId, userType: userType },
              }"
                index="/center/indexs"
            >
              <img src="~/static/center/jianli.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的简历</span>
            </el-menu-item>
            <el-menu-item index="/center/work/">
              <img src="~/static/center/qiuzhi.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的投递</span>
            </el-menu-item>
            <el-menu-item index="/center/training/">
              <img src="~/static/center/peixun.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的学习</span>
            </el-menu-item>
            <el-menu-item index="/center/myCreate/" v-if="location == '湖南省'">
              <img src="~/static/center/create.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的创业</span>
            </el-menu-item>
            <el-menu-item
                index="/center/myAppoint/"
                v-if="location == '湖南省'"
            >
              <img src="~/static/center/create.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的预约</span>
            </el-menu-item>
            <el-menu-item index="/center/myask/" v-if="location == ''">
              <img src="~/static/center/Q.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的问答</span>
            </el-menu-item>
            <el-menu-item index="/center/column/" v-if="location == ''">
              <img src="~/static/center/column.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的专栏</span>
            </el-menu-item>
            <el-menu-item index="/center/collect/">
              <img src="~/static/center/collect.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的收藏</span>
            </el-menu-item>
            <el-menu-item index="/center/myGuide/" v-if="location == '陕西省'">
              <img src="~/static/shanxi/index/qiuzhi.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">就业指导</span>
            </el-menu-item>
            <el-menu-item index="/center/myNeeds/">
              <img src="~/static/center/xuqiu.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的需求</span>
            </el-menu-item>
            <el-menu-item
                index="/center/myContest/"
                v-if="location == '湖南省'"
            >
              <img src="~/static/center/contest.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">我的报名</span>
            </el-menu-item>
            <el-menu-item index="/center/mentalAppointment/" v-if="isHide">
              <img src="~/static/center/tv.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">心理预约</span>
            </el-menu-item>
            <el-menu-item index="/center/orderMange/" v-if="isHide">
              <img src="~/static/center/order.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">订单管理</span>
            </el-menu-item>
            <el-menu-item index="/center/worMange/" v-if="isHide">
              <img src="~/static/center/workt.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">作品管理</span>
            </el-menu-item>
            <el-menu-item index="/center/videoInterview/" v-if="isHide">
              <img src="~/static/center/video.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">视频面试</span>
            </el-menu-item>
            <el-menu-item index="/center/flexbile/" v-if="isHide">
              <img
                  src="~/static/center/flexible.png"
                  alt aria-hidden="true"
                  class="block_inline"
              />
              <span slot="title">灵活就业</span>
            </el-menu-item>
            <el-menu-item index="/center/guideCreate/" v-if="isHide">
              <img
                  src="~/static/center/createJob.png"
                  alt aria-hidden="true"
                  class="block_inline"
              />
              <span slot="title">就创业指导</span>
            </el-menu-item>
            <el-menu-item index="/center/orderMange/">
              <img src="~/static/center/order.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">订单管理</span>
            </el-menu-item>
            <el-menu-item index="/center/worMange/">
              <img src="~/static/center/contest.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">作品管理</span>
            </el-menu-item>
            <el-menu-item index="/lanzhou/center/blind" v-if="location == '甘肃省'">
              <img src="~/static/center/xuqiu.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">盲医考继续教育</span>
            </el-menu-item>
            <el-submenu index="/enterprise/centre/phone/">
              <template slot="title">
                <img
                    src="~/static/enterprise/setting.png"
                    alt aria-hidden="true"
                    class="block_inline"
                />
                <span slot="title">功能设置</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/settingUser/">
                  <span slot="title">社交账号绑定</span>
                </el-menu-item>
                <el-menu-item index="/setting/">
                  <span slot="title" class="fonts">修改手机号</span>
                </el-menu-item>
                <el-menu-item index="/settingEmail/">
                  <span slot="title" class="fonts">绑定邮箱</span>
                </el-menu-item>
                <el-menu-item index="/settingPass/">
                  <span slot="title" class="fonts">修改密码</span>
                </el-menu-item>
                <el-menu-item index="/settingPrivacy/">
                  <span slot="title" class="fonts">修改隐私设置</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item @click="logout">
              <img src="~/static/center/out.png" alt aria-hidden="true" class="block_inline"/>
              <span slot="title">退出登录</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-col>
      <el-col :span="20" class="content">
        <router-view/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "center",
  data() {
    return {
      active: "1",
      userType: "",
      touristType: "",
      memberId: "",
      isHide: false,
      location: "",
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logout() {
      this.$confirm("确定要退出当前登录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$store.commit("SET_USER_INFO", 1);
            this.$store.commit("SET_TOKEN", "");
            window.localStorage.removeItem("access_token");
            window.localStorage.removeItem("userInfo");
            window.localStorage.removeItem("userType");
            this.$router.push("/");
            this.reload();
            this.$message({
              type: "warning",
              message: "退出成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消退出",
            });
          });
    },
  },
  created() {
    let login = this.$store.state.userType?this.$store.state.login:JSON.parse(localStorage.getItem('userInfo'));
    console.log('登录',login)
    if (login) {
      this.memberId = login.id;
      this.userType = login.userType;
      this.touristType = login.touristType;
    }
    this.location = localStorage.getItem("locationSite") || "";
  },
  mounted() {
    this.$emit("goRouter", "/center/index");
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/center/center.less";

.main {
  width: 1200px;
  margin: 10px auto;

  .content {
    // background: white;
    padding-left: 0px !important;
  }
}

.el-col-4 {
  width: 160px;
}

.el-menu-item:after {
  transition: none;
}

.el-menu-item.is-active {
  color: #333333;
}

.el-menu {
  border-right: none;
}
</style>
